<template>
  <div class="login-container">
    <div class="login-background"></div>
    <div class="login-card">
      <!-- <img src="@/assets/logo.png" alt="Logo" class="logo" /> -->
       <h1 class="platform-title">光污染项目管理平台</h1>
      <h2>欢迎登录</h2>
      <form @submit.prevent="handleLogin">
        <div class="form-group">
          <label for="username">用户名</label>
          <input v-model="username" type="text" id="username" placeholder="请输入用户名" required />
        </div>
        <div class="form-group">
          <label for="password">密码</label>
          <input
            v-model="password"
            type="password"
            id="password"
            placeholder="请输入密码"
            required
          />
        </div>
        <button type="submit" class="login-button">登录</button>
      </form>
      <p class="signup-text"></p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { login } from '@/service/PrjInfo'
import { ElMessage } from 'element-plus'

const username = ref('')
const password = ref('')
const router = useRouter()

const handleLogin = async () => {
  if (username.value && password.value) {
    // 假设登录验证成功后重定向
    try {
      const response = await login(username.value, password.value)
      localStorage.setItem('token', response.token);
      console.log('登录成功')
      router.push({ name: 'home' })
    } catch (error) {
      ElMessage({
        type: 'error',
        message: `登录失败，请检查用户名和密码`
      })
    }
  } else {
    alert('请输入有效的用户名和密码')
  }
}
</script>

<style scoped lang="scss">
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow: hidden;
  background: #f3f4f6;
  position: relative;
}

.login-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../assets/background.jpg');
  background-size: cover;
  background-position: center;
  filter: blur(5px);
}

.login-card {
  width: 450px;
  padding: 40px;
  border-radius: 15px;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  text-align: center;
  animation: slideIn 0.6s ease-in-out;
  z-index: 3;
  .platform-title {
  color: #504f4f; /* A color that matches or complements the login card */
  font-size: 2em;
  font-weight: bold;
  margin-bottom: 15px;
  text-align: center;
}
}

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateY(-30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.logo {
  width: 80px;
  margin-bottom: 20px;
  animation: bounce 1.5s infinite;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

.login-card h2 {
  color: #333;
  font-size: 1.8em;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 20px;
  text-align: left;

  label {
    font-size: 0.9em;
    color: #555;
    display: block;
    margin-bottom: 5px;
  }

  input {
    width: 100%;
    padding: 12px;
    border-radius: 8px;
    border: 1px solid #ddd;
    font-size: 1em;
    outline: none;
    transition: border-color 0.3s;

    &:focus {
      border-color: #42b983;
    }
  }
}

.login-button {
  width: 100%;
  padding: 12px;
  border-radius: 8px;
  border: none;
  background-color: #42b983;
  color: #fff;
  font-size: 1.1em;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #36a472;
  }
}

.signup-text {
  margin-top: 15px;
  font-size: 0.9em;
  color: #555;

  a {
    color: #42b983;
    text-decoration: none;
    transition: color 0.3s;

    &:hover {
      color: #36a472;
    }
  }
}
</style>

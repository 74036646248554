import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import DetailView from '@/views/DetailView.vue'
import Login from '@/components/login.vue'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomeView,
      meta: { requiresAuth: true }
    },
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/detail/:id/:type',
      name: 'detail',
      component: DetailView,
      meta: { requiresAuth: true },
      children: [
        {
          path: 'photo',
          name: 'Photo',
          component: () => import('@/components/detail/PhotoView.vue'),
          meta: { requiresAuth: true }
        },
        {
          path: 'document',
          name: 'Document',
          component: () => import('@/components/detail/Document.vue'),
          meta: { requiresAuth: true }
        },
        {
          path: 'dwg',
          name: 'Dwg',
          component: () => import('@/components/detail/Dwg.vue'),
          meta: { requiresAuth: true }
        }
      ]
    }
  ]
})

// 导航守卫，检查是否已登录
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    const token = localStorage.getItem('token');
    if (token) {
      next();
    } else {
      next({ name: 'Login' });
    }
  } else {
    next();
  }
});

export default router

<template>
  <div class="avatar" @click="toggleMenu">
    <!-- <img src="../assets/avatar.png" class="avatar-img" /> -->
    <el-popover v-model:visible="showMenu" placement="bottom" width="150" trigger="click" >
      <div class="menu-content">
        <el-button type="danger" @click="logout" size="mini">退出登录</el-button>
      </div>
      <template #reference>
        <img :src="avatarUrl" alt="用户头像" class="avatar-img" />
      </template>
    </el-popover>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import { ElPopover, ElButton } from 'element-plus';
import { parseJwt } from '@/utils/tools'

const showMenu = ref(false);
const router = useRouter();
const avatarUrl = computed(() => {
  const token = localStorage.getItem('token');
  if (!token) return new URL('@/assets/avatar.png', import.meta.url).href;
  let temp = parseJwt(token);
  if (temp.role === 'admin') {
    return new URL('@/assets/userAvatar.png', import.meta.url).href;
  } else {
    return new URL('@/assets/avatar.png', import.meta.url).href;
  }
});

const toggleMenu = () => {
  showMenu.value = !showMenu.value;
}

const logout = () => {
  localStorage.removeItem('token');
  showMenu.value = false;
  router.push({ name: 'Login' });
}
</script>

<style scoped>
.avatar {
  position: absolute;
  top: 13px;
  right: 30px;
  width: 40px;
  height: 40px;
  cursor: pointer;
}
.avatar-img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}
.menu-content {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}
</style>
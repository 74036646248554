<template>
  <div class="info-window-content">
    <div class="title">新建项目</div>
    <div class="prj-info">项目信息</div>
    <div class="alert-info">
      <p class="alert-item">
        <span class="name">工程类型:</span>
        <select v-model="prjInfos.type" class="prj-input">
          <option value='1'>建筑平立面测绘</option>
          <option value="2">地形图</option>
          <option value="3">无人机航拍</option>
        </select>
      </p>
      <p class="alert-item">
        <span class="name">项目名称:</span>
        <input v-model="prjInfos.name" class="prj-input" />
      </p>
      <p class="alert-item">
        <span class="name">项目经理:</span>
        <input v-model="prjInfos.manager" class="prj-input" />
      </p>
      <p class="alert-item">
        <span class="name">项目编号:</span>
        <input v-model="prjInfos.number" class="prj-input" />
      </p>
      <p class="alert-item">
        <span class="name">项目描述:</span>
        <input v-model="prjInfos.description" class="prj-input" />
      </p>
      <!-- <p class="alert-item">
                <span class="name">经度:</span>
                <input v-model="prjInfos.lng" class="prj-input" />
            </p>
            <p class="alert-item">
                <span class="name">纬度:</span>
                <input v-model="prjInfos.lat" class="prj-input" />
            </p> -->
    </div>
    <div class="confirm">
      <button class="cancel-btn" @click="cancel">取消</button>
      <button class="confirm-btn" @click="confirm">确定</button>
    </div>
    <div class="info-window-arrow"></div>
  </div>
</template>

<script lang="ts" setup>
import { defineProps, ref } from 'vue'
import { updatePrjInfo } from '@/service/PrjInfo'
import { ElMessage } from 'element-plus'

const props = defineProps({
  position: Object,
  closeInfoWindow: Function,
  addedPrj: Function,
  drawPolygon: Function,
  showDialog: Function
})
const prjInfos: any = ref({
  id: 0,
  name: '',
  manager: '',
  description: '',
  number: '',
  type: 1,
  lng: props.position.lng,
  lat: props.position.lat
})

const confirm = () => {
  if (prjInfos.value.name === '') {
    ElMessage.warning('请输入项目名称')
    return
  }
  // if(prjInfos.value.manager === '') {
  //     ElMessage.warning('请输入项目经理')
  //     return
  // }
  if (prjInfos.value.number === '') {
    ElMessage.warning('请输入项目编号')
    return
  }
  // if(prjInfos.value.description === '') {
  //     ElMessage.warning('请输入项目描述')
  //     return
  // };
  updatePrjInfo(prjInfos.value).then((res) => {
    let {insertId, lat, lng} = res.data
    prjInfos.value.id = insertId
    props.addedPrj(prjInfos.value)
    props.showDialog(insertId, lat, lng)
  })
}

const cancel = () => {
  props.closeInfoWindow()
}

const toPrj = (id: number) => {
  window.location.href = `/project/${id}`
}
</script>

<style lang="scss" scoped>
.info-window-content {
  position: relative;
  background-color: white;
  padding: 8px;
  border-radius: 5px;
  // border: 1px solid #ccc;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  .title {
    font-size: 16px;
    color: #508ef5;
  }
  .prj-info {
    font-size: 18px;
    font-weight: bold;
    color: #333;
    margin-bottom: 15px;
    text-align: center;
  }
  .alert-info {
    // width: 590px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 10px;
    background-color: #fff;
    border-radius: 5px;
    .alert-item {
      margin-bottom: 10px;
      padding: 0 0 10px 10px;
      // border-bottom: 1px solid #ccc;
      font-size: 14px;
      color: #666;
      display: flex;
      .name {
        display: inline-block;
        width: 75px;
        height: 25px;
      }
      .prj-input {
        height: 25px;
        width: 200px;
        border: 1px solid #ccc;
      }
    }
  }
  .threshold-settings {
    background-color: #f7f7f7;
    border-radius: 8px;
    padding: 20px 20px 5px;
    width: max-content;
    .title {
      font-size: 18px;
      font-weight: bold;
      color: #333;
      margin-bottom: 15px;
      text-align: center;
    }
    .value-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 570px;
      .value-select {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        flex-basis: calc(50%);
        .label {
          width: 80px;
          height: 25px;
          line-height: 25px;
          text-align: right;
        }
        .select-menu {
          margin-left: 10px;
          padding: 5px 10px;
          border: 1px solid #ccc;
          border-radius: 4px;
          background-color: white;
          cursor: pointer;
          option {
            padding: 5px;
          }
        }
      }
    }
  }

  .confirm {
    display: flex; // 使用 Flexbox 布局
    justify-content: center; // 按钮水平居中
    gap: 20px; // 按钮之间的间隙

    .confirm-btn,
    .cancel-btn {
      padding: 10px 20px; // 内边距
      border: none; // 移除边框
      font-size: 16px; // 字体大小
      // font-weight: bold; // 文字加粗
      cursor: pointer; // 鼠标悬停变成手形图案
      transition: background-color 0.3s ease; // 过渡动画
      border-radius: 5px; // 边框圆角
      outline: none; // 移除焦点边框
    }

    .confirm-btn {
      color: #fff; // 字体颜色白色
      background-color: #5a97f9; // 背景颜色浅绿色
      &:hover {
        background-color: darken(#5595fb, 10%); // 悬停时背景颜色变深
      }
    }

    .cancel-btn {
      color: #fff; // 字体颜色白色
      background-color: #dc6e6b; // 背景颜色浅红色
      &:hover {
        background-color: darken(#d9534f, 10%); // 悬停时背景颜色变深
      }
    }
  }

  .info-window-arrow {
    position: absolute;
    left: 50%;
    bottom: -20px; /* 根据箭头大小调整 */
    width: 0;
    height: 0;
    margin-left: -10px; /* 需要是宽度的一半负值以确保居中 */
    border-width: 10px;
    border-style: solid;
    border-color: white transparent transparent transparent;
    filter: drop-shadow(0 2px 6px rgba(0, 0, 0, 0.2));
  }
  .value-select {
    width: 550px;
  }
}
</style>

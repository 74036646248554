<template>
  <div class="detail-view">
    <nav class="nav-bar">
      <router-link to="/" class="home-link">
        <img src="@/assets/home.svg" alt="Home">
      </router-link>
      <router-link v-if="type!=2" :to="{ name: 'Photo', params: { id: $route.params.id } }" class="nav-link" active-class="active">图片</router-link>
      <router-link v-if="type==1" :to="{ name: 'Document', params: { id: $route.params.id } }" class="nav-link" active-class="active">文档</router-link>
      <router-link v-if="type!=3" :to="{ name: 'Dwg', params: { id: $route.params.id } }" class="nav-link" active-class="active">CAD</router-link>
      <div class="upload-button">
        <UploadFiles />
      </div>
    </nav>
    <div class="content">
      <router-view />
    </div>
  </div>
</template>

<script setup>
import { useRoute, useRouter } from 'vue-router';
import UploadFiles from '@/components/detail/UploadFiles.vue';
const route = useRoute()
const router = useRouter()
const type = route.params.type;
const id = route.params.id;
if (type == 1 || type == 3) {
   router.push({ name: 'Photo', params: { id: id } })
} else if(type == 2) {
   router.push({ name: 'Dwg', params: { id: id } })
}
</script>

<style lang="scss" scoped>
.detail-view {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 20px);
  background-color: #f4f4f9;

  .nav-bar {
    display: flex;
    align-items: center;
    background-color: #3b5998;
    padding: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

    .home-link {
      color: white;
      text-decoration: none;
      font-size: 24px;
      margin:0 20px 0 10px;
      &:hover {
        color: #dfe3ee;
      }
    }

    .nav-link {
      color: white;
      text-decoration: none;
      padding: 10px 20px;
      margin: 0 10px;
      border-radius: 5px;
      transition: background-color 0.3s, color 0.3s;

      &:hover {
        background-color: #2d4373;
      }

      &.active {
        background-color: #2d4373;
      }
    }
    .upload-button {
      margin-left: auto;
    }
  }

  .content {
    flex-grow: 1;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 20px;
    overflow-y: auto;
  }
}
</style>
